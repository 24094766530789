import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetailsTwo from "@/components/service-details-2";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import slider42 from "@/images/slider/4_2.jpg";

const ServiceDetailsPageTwo = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="DevOps Solutions">
          <HeaderOne />
          <PageBanner title="DevOps" name="Services" image={slider42}/>
          <ServiceDetailsTwo />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPageTwo;
