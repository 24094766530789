import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
import devops1 from "@/images/devops1.png";
import devops2 from "@/images/devops2.jpeg";
import devops3 from "@/images/devops3.jpg";
const ServiceDetailsContentTwo = () => {
  return (
    <div className="serviceArea">
      <img src={devops1} alt="" />
      <h2>The Best And Reliable Devops Services To Help You Grow</h2>
      <p>
        You always want your teams to work and coordinate in a way that is the 
        most productive. However, multiple teams working simultaneously on a 
        project make collaboration and resource management very difficult, where 
        they have to meet productivity deadlines, along with rolling out and 
        deploying quality software. This is where teams, and businesses, need DevOps. 
        It combines development and operations through optimized practices and tools 
        so that the software development process runs quickly and securely. 
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={devops3} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>DevOps Services</h3>
          <p>
            We offer the following services:
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Designing Cloud Infrastructure 
            </li>
            <li>
              <i className="fa fa-check-square"></i>Deploying and Maintaining Cloud Infrastructure 
            </li>
            <li>
              <i className="fa fa-check-square"></i>Integrating Security 
            </li>
            <li>
              <i className="fa fa-check-square"></i>Continuous Integration And Delivery (CI/CD)
            </li>
            <li>
              <i className="fa fa-check-square"></i>Migrations
            </li>
          </ul>
        </div>
      </div>
      <p>
        We do this through the best DevOps technologies in the industry, 
        AWS and Azure. The cloud infrastructure is built with a strategy 
        around your needs, a secure transfer of your current infrastructure, 
        databases, and servers is done through migrations, along with 
        implementing CI/CD practices and ensuring security around all the 
        operations and infrastructure. 
      </p>
    </div>
  );
};

export default ServiceDetailsContentTwo;
